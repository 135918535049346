/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import ViewBox from 'vux/src/components/view-box';
import DateDialog from '@/components/EdDate/Index';
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: 'SpendDetail',
  components: {
    Cell: Cell,
    Group: Group,
    ViewBox: ViewBox,
    DateDialog: DateDialog,
    Scroll: Scroll
  },
  data: function data() {
    return {
      list: [],
      //消费列表
      spendSum: {
        newMoney: 0.0,
        newAdd: 0.0,
        newSub: 0.0
      },
      showConfirm: false,
      //是否显示确认
      tabIndex: 0,
      //tab索引
      beginDate: '',
      //开始时间
      endDate: '',
      //结束时间
      currDate: '',
      //当前日期
      userSerial: 0,
      //用户编号
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      emptyShow: false,
      //是否显示数据为空样式
      initFlag: true,
      pageIndex: 1,
      //当前页数
      pageSize: 20,
      //每页显示数
      totalPage: 0,
      //总页数
      refund: 0,
      //是否能退款
      unitList: ['元', '份'],
      activeSpan: 0,
      countbalance: 1,
      //是否显示份
      tabList: [],
      active: 0
    };
  },
  methods: {
    gotoxiangqin: function gotoxiangqin(aa) {
      this.$router.push({
        name: 'SpendDetailDetail',
        query: {
          xh: aa.xh
        }
      });
    },
    //获取使用方式
    getTitle: function getTitle(record) {
      return record.channel;
      /* 此处修改为后台维护该字段前端不在维护2021/12/15 */
      // if (this.tabIndex === 3) {
      //     return record.channel
      // } else if (this.tabIndex == 0) {
      //     return record.xfType
      // } else {
      //     if (record.addType == 1) {
      //         return '移动端'
      //     }
      //     if (record.addType == 0 && (record.depName == null || record.depName == '')) {
      //         return '电脑端'
      //     } else {
      //         return '刷卡'
      //     }
      // }
    },
    // 查询显示哪个模块
    getRefund: function getRefund() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Spend.getRefund().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.refund = data.refund;
          _this.countbalance = data.countbalance;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    // 下拉刷新
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      this.beginDate = this.$utils.DateUtils.getMonthStart(this.beginDate);
      this.endDate = this.$utils.DateUtils.getMonthEnd(this.endDate);
      this.pageIndex = 1;
      this.getMxRecord();
      this.getXfRecord();
    },
    //上划加载更多
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1; // 当前页码大于总页数

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getMxRecord();
      this.getXfRecord();
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    onItemClick: function onItemClick(jllx) {
      if (jllx == 55) {
        jllx = this.tabIndex;
      }

      this.$utils.Tools.loadShow();
      this.list = [];
      this.tabIndex = jllx;
      this.pageIndex = 1;
      this.active = jllx;
      this.getMxRecord();
      this.getXfRecord();
    },
    //选择日期点击确定
    onConfirm: function onConfirm(beginDate, endDate) {
      this.initFlag = false;
      this.list = [];
      this.beginDate = this.$utils.Tools.repairDate(beginDate);
      this.endDate = this.$utils.Tools.repairDate(endDate);
      this.pageIndex = 1;
      this.getMxRecord();
      this.getXfRecord();
    },
    getXfRecord: function getXfRecord() {
      var _this2 = this;

      // 点击不是充值/退款
      if (this.tabIndex != 1 && this.tabIndex != 3 && this.activeSpan == 1) {
        this.getXfFenRecord();
        return;
      }

      this.activeSpan = 0;
      this.$api.Spend.getXfRecord({
        userSerial: this.userSerial,
        beginDate: this.beginDate,
        endDate: this.endDate
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          if (_this2.activeSpan == 0) {
            _this2.spendSum = data;
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    // lwt  2021-01-09 份汇总
    getXfFenRecord: function getXfFenRecord() {
      var _this3 = this;

      this.$api.Spend.getXfFenRecord({
        userSerial: this.userSerial,
        beginDate: this.beginDate,
        endDate: this.endDate
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          // 赋值为份的统计
          _this3.spendSum.newMoney = data.newMoney;
          _this3.spendSum.newSub = data.newSub;

          _this3.$forceUpdate();
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // 消费明细查询
    getMxRecord: function getMxRecord() {
      var _this4 = this;

      // 加了判断 如果切换成份的话  就直接去执行份的
      if (this.activeSpan == 1 && this.tabIndex != 1 && this.tabIndex != 3) {
        this.getMxFenRecord();
        return;
      }

      this.activeSpan = 0;
      this.emptyShow = false;
      this.$api.Spend.getMxRecord({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.userSerial,
          type: this.tabIndex,
          beginDate: this.beginDate,
          endDate: this.endDate
        }
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          var _this4$list;

          (_this4$list = _this4.list).push.apply(_this4$list, _toConsumableArray(data.records));

          _this4.pageIndex = data.pageIndex;
          _this4.totalPage = data.totalPage;

          if (_this4.list.length === 0) {
            _this4.emptyShow = true;
          }

          _this4.updateScroll();
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    // 2021-01-09 lwt  份数查询
    getMxFenRecord: function getMxFenRecord() {
      var _this5 = this;

      if (this.tabIndex == 1 || this.tabIndex == 3) {
        return;
      }

      this.emptyShow = false;
      this.$api.Spend.getMxFenRecord({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          userSerial: this.userSerial,
          type: this.tabIndex,
          beginDate: this.beginDate,
          endDate: this.endDate
        }
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          var _this5$list;

          (_this5$list = _this5.list).push.apply(_this5$list, _toConsumableArray(data.records));

          _this5.pageIndex = data.pageIndex;
          _this5.totalPage = data.totalPage;

          if (_this5.list.length === 0) {
            _this5.emptyShow = true;
          }

          _this5.updateScroll();
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    //获取当前日期
    getSysDate: function getSysDate() {
      var _this6 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this6.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this6.$code.success) {
          _this6.$utils.Tools.toastShow(msg);
        } else {
          _this6.currDate = data.sysDate;
          _this6.beginDate = _this6.$utils.DateUtils.getMonthStart(data.sysDate);
          _this6.endDate = _this6.$utils.DateUtils.getMonthEnd(data.sysDate);

          _this6.getMxRecord();

          _this6.getXfRecord();
        }
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem('userSerial'); // 如果带有默认的选择值

    if (this.$route.query.active) {
      this.active = this.$route.query.active;
      this.tabIndex = this.$route.query.active;
    } else {
      this.active = 0;
      this.tabIndex = 0;
    }

    this.getSysDate();
    this.getRefund();
  }
};